import request from "../../utils/request";
import axios from "axios";
import { getToken } from "@/utils/auth";

//骑手列表
export function getRiderList(params) {
  return request({
    url: "/api/getRiderList",
    method: "get",
    params: params,
  });
}
