<template>
  <div class="select-container">
    <div class="header">
      <div class="title">订单渠道</div>
    </div>
    <div class="content">
      <el-input placeholder="渠道名称" v-model="keyword" size="small" :clearable="true" :style="{marginBottom: '12px'}">
        <el-button slot="append" icon="el-icon-search" size="mini" @click="search"></el-button>
      </el-input>
      <div class="item" @click="handleSelectChannel('all')" :style="{color: rChannel==='all'?'#409EFF':''}" v-if="this.channelList.length === this.allChannelList.length">全部</div>
      <div class="channelContainer" v-loading="loading">
        <div v-for="(item) in channelList" :key="item.code" @click="handleSelectChannel(item.code)" class="item" :style="{color: rChannel===item.code?'#409EFF':''}">
          {{item.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {getOrdPickingAllChannelList} from "@/api/delivery";

export default {
  name: "SelectChannel",
  props: {
  },
  data() {
    return {
      loading:false,
      keyword:'',
      channelList:[],
      allChannelList:[],
      rChannel:'all',//当前选中渠道
    };
  },
  created() {
  },
  mounted() {
    this.handleSelectChannel('all');
    this.getOrdPickingAllChannelList();
  },
  methods: {
    getOrdPickingAllChannelList() {
      this.loading = true;
      getOrdPickingAllChannelList().then(result => {
        if (result) {
          this.channelList = result.data;
          this.allChannelList = result.data;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    search() {
      this.rChannel = '';
      this.channelList = this.allChannelList.filter(item => {
        return item['name'].toLowerCase().includes(this.keyword.toLowerCase());
      });
      if( this.allChannelList.length === this.channelList.length){
        this.rChannel = 'all';
      }else if(this.channelList.length > 0){
        this.rChannel = this.channelList[0].code;
      }else{
        this.rChannel = '-1';
      }
      this.$emit('selectChannel', this.rChannel);
    },
    handleSelectChannel(code) {
      this.rChannel = code;
      this.$emit('selectChannel', code);
    },
  },
};
</script>

<style lang="scss" scoped>
.select-container{
  float: left;
  width: 220px;
  height: 100%;
  margin-right: 20px;
  border: 1px solid #ddd;
  .header{
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom:1px solid #ddd;
    padding-left: 15px;
    .title{
      width: 60px;
      height: 100%;
      border-bottom: 2px solid #409EFF;
    }
  }
  .content{
    width: 100%;
    height: calc(100% - 40px);
    padding: 15px 10px;
    .item{
      width: 100%;
      height: auto;
      line-height: 18px;
      font-size: 14px;
      padding-left: 10px;
      margin-bottom: 18px;
      cursor: pointer;
    }
  }
}
.channelContainer{
  width: 100%;
  height: calc(100% - 80px);
  overflow-y: auto;
}
</style>
